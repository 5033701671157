import React from 'react';
import "../Pages/News.css";

const News = () => {
  return (
    <>
        <div className="news">
            <span>-	Upcoming</span>
        </div>
    </>
  )
}

export default News