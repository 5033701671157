import React from 'react';
import { useState } from 'react';
import img from "../../Images/Handmade Metal Art/1 (1).jpg";
import img2 from "../../Images/Handmade Metal Art/1 (10).jpg";
import img3 from "../../Images/Handmade Metal Art/1 (13).jpg";
import img4 from "../../Images/Handmade Metal Art/1 (14).jpg";
import img5 from "../../Images/Handmade Metal Art/1 (15).jpg";
import img6 from "../../Images/Handmade Metal Art/1 (16).jpg";
import img7 from "../../Images/Handmade Metal Art/1 (17).jpg";
import img8 from '../../Images/Handmade Metal Art/1 (19).jpg';
import img9 from "../../Images/Handmade Metal Art/1 (21).jpg";
import img10 from "../../Images/Handmade Metal Art/1 (23).jpg";
import img11 from "../../Images/Handmade Metal Art/1 (26).jpg";
import img12 from "../../Images/Handmade Metal Art/1 (36).jpg";
import img13 from "../../Images/Handmade Metal Art/1 (37).jpg";
import img14 from "../../Images/Handmade Metal Art/1 (38).jpg";
import img15 from "../../Images/Handmade Metal Art/1 (39).jpg";
import img16 from "../../Images/Handmade Metal Art/1 (4).jpg";
import img17 from "../../Images/Handmade Metal Art/1 (40).jpg";
import img18 from "../../Images/Handmade Metal Art/1 (41).jpg";
import img19 from "../../Images/Handmade Metal Art/1 (42).jpg";
import img20 from "../../Images/Handmade Metal Art/1 (43).jpg";
import img21 from "../../Images/Handmade Metal Art/1 (45).jpg";
import img22 from "../../Images/Handmade Metal Art/1 (47).jpg";
import img23 from "../../Images/Handmade Metal Art/1 (48).jpg";
import img24 from "../../Images/Handmade Metal Art/1 (49).jpg";
import img25 from "../../Images/Handmade Metal Art/1 (53).jpg";
import img26 from "../../Images/Handmade Metal Art/1 (54).jpg";
import img27 from "../../Images/Handmade Metal Art/1 (55).jpg";
import img28 from "../../Images/Handmade Metal Art/1 (56).jpg";
import img30 from "../../Images/Handmade Metal Art/1 (7).jpg";
import img31 from "../../Images/Handmade Metal Art/1 (9).jpg";

const Handmade_Metal_Art = () => {

    const initialDisplayCount = 16;
    const [imgDisplayCount, setImgDisplayCount] = useState(initialDisplayCount);
    const [showMore, setShowMore] = useState(true);
  
  
    const handleButtonClick = () => {
      if (showMore) {
        setImgDisplayCount(imgData.length);
      } else {
        setImgDisplayCount(initialDisplayCount);
      }
      setShowMore(!showMore);
    };


const imgData = [
    {
        id: 1,
        images: img
    },
    {
        id: 2,
        images: img2
    },
    {
        id: 3,
        images: img3
    },
    {
        id: 4,
        images: img4
    },
    {
        id: 5,
        images: img25
    },
    {
        id: 6,
        images: img6
    },
    {
        id: 7,
        images: img7
    },
    {
        id: 8,
        images: img8
    },
    {
        id: 9,
        images: img9
    },
    {
        id: 10,
        images: img10
    },

    {
        id: 11,
        images: img11
    },
    {
        id: 12,
        images: img12
    },
    {
        id: 13,
        images: img13
    },
    {
        id: 14,
        images: img14
    },
    {
        id: 15,
        images: img15
    },
    {
        id: 16,
        images: img16
    },
    {
        id: 17,
        images: img17
    },
    {
        id: 18,
        images: img18
    },
    {
        id: 19,
        images: img19
    },
    {
        id: 20,
        images: img20
    },
    {
        id: 21,
        images: img21
    },
    {
        id: 22,
        images: img22
    },
    {
        id: 23,
        images: img23
    },
    {
        id: 24,
        images: img24
    },
    {
        id: 25,
        images: img25
    },
    {
        id: 26,
        images: img26
    },
    {
        id: 27,
        images: img27
    },
    {
        id: 28,
        images: img27
    },
    {
        id: 29,
        images: img28
    },
    {
        id: 29,
        images: img28
    },
    {
        id: 30,
        images: img30
    },
    {
        id: 31,
        images: img31
    },
    {
        id: 32,
        images: img5
    }

]


  return (
    <>
        <div className="artist">
        <div className="gally">
          {imgData.slice(0, imgDisplayCount).map((item) => {
            return (
              <div key={item.id} className="imgbox">
                <img src={item.images} alt={item.name} />
              </div>
            );
          })}
        </div>
        <div className="btnshow">
        <button onClick={handleButtonClick}>
          {showMore ? "See More" : "Hide More"}
        </button>
        </div>
      </div>
    </>
  )
}

export default Handmade_Metal_Art