import React from "react";
import "../Components/Footer.css";
import { Link } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import { BsFacebook, BsInstagram, BsLinkedin, BsWhatsapp } from "react-icons/bs";

const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="footer_text">
          <h2>Get in Touch</h2>
          <Link to="/contact" className="btn">
            Say Hello
            <span>
              <BsArrowRight />
            </span>
          </Link>
        </div>
        <div className="footer_line"></div>
        <div className="footer_info">
          <span>Email</span>
          <br />
          <a href="email: info@theonlyplanetearth.com">info@theonlyplanetearth.com</a>
          <br /> <br /> <br />
          <span>Phone</span>
          <br />
          <a href="tel: +65 90740899">+65 90740899</a>
          <br /> <br /> <br />
          <div className="socile_link">
            <a href="https://www.facebook.com/planetearthsingapore" target="__blank"><BsFacebook/></a>
            <a href="https://www.instagram.com/planetearthsingapore/" target="__blank"><BsInstagram/></a>
            <a href="https://www.linkedin.com/company/planetearthsingapore/" target="_-blank"><BsLinkedin/></a>
            <a href="https://wa.me/+6590740899" target="__blank"><BsWhatsapp/></a>
          </div>
        </div>
      </div>

      <div className="bootom">
        <span>© 2023 Copyright: theonlyplanetearth.com</span>
      </div>
    </>
  );
};

export default Footer;
