import React from "react";
import { useState } from "react";
import img from "../../Images/a (1).jpg";
import img2 from "../../Images/a (2).jpg";
import img3 from "../../Images/a (3).jpg";
import img4 from "../../Images/a (4).jpg";
import img5 from "../../Images/a (5).jpg";
import img6 from "../../Images/a (6).jpg";
import img7 from "../../Images/a (7).jpg";
import img8 from "../../Images/a (8).jpg";
import img9 from "../../Images/a (9).jpg";
import img10 from "../../Images/a (10).jpg";
import img11 from "../../Images/a (11).jpg";
import img12 from "../../Images/a (12).jpg";
import img13 from "../../Images/a (13).jpg";
import img14 from "../../Images/a (14).jpg";
import img15 from "../../Images/a (15).jpg";
import img16 from "../../Images/a (16).jpg";
import img17 from "../../Images/a (17).jpg";
import img18 from "../../Images/a (18).jpg";
import img23 from "../../Images/a (23).jpg";
import img24 from "../../Images/a (24).jpg";

const Realistic_Animal_Artistry = () => {
  const initialDisplayCount = 16;
  const [imgDisplayCount, setImgDisplayCount] = useState(initialDisplayCount);
  const [showMore, setShowMore] = useState(true);

  const handleButtonClick = () => {
    if (showMore) {
      setImgDisplayCount(imgData.length);
    } else {
      setImgDisplayCount(initialDisplayCount);
    }
    setShowMore(!showMore);
  };

  const imgData = [
    {
      id: 1,
      images: img,
    },
    {
      id: 2,
      images: img2,
    },
    {
      id: 3,
      images: img3,
    },
    {
      id: 4,
      images: img4,
    },
    {
      id: 5,
      images: img5,
    },
    {
      id: 6,
      images: img6,
    },
    {
      id: 7,
      images: img7,
    },
    {
      id: 8,
      images: img8,
    },
    {
      id: 9,
      images: img9,
    },
    {
      id: 10,
      images: img10,
    },
    {
      id: 11,
      images: img11,
    },
    {
      id: 12,
      images: img12,
    },
    {
      id: 13,
      images: img13,
    },
    {
      id: 14,
      images: img14,
    },
    {
      id: 15,
      images: img15,
    },
    {
      id: 16,
      images: img16,
    },
    {
      id: 17,
      images: img17,
    },
    {
      id: 18,
      images: img18,
    },
    {
      id: 23,
      images: img23,
    },
    {
      id: 24,
      images: img24,
    },
  ];

  return (
    <>
      <div className="artist">
        <div className="gally">
          {imgData.slice(0, imgDisplayCount).map((item) => {
            return (
              <div key={item.id} className="imgbox">
                <img src={item.images} alt={item.name} />
              </div>
            );
          })}
        </div>
        <div className="btnshow">
          <button onClick={handleButtonClick}>
            {showMore ? "See More" : "Hide More"}
          </button>
        </div>

        <div className="headtext">
          <h2>Capturing Nature's Essence: Realistic Animal Artistry</h2>
          <p>
            Realistic animal art is a captivating tribute to the natural world,
            meticulously crafted to showcase the beauty and diversity of the
            animal kingdom. These artworks transport us to the heart of the
            wild, where each creature becomes a vivid ambassador, exuding
            intelligence, texture, and character. They celebrate the
            extraordinary variety of life on Earth, from the power of lions to
            the grace of hummingbirds, fostering curiosity and deepening our
            environmental appreciation. Moreover, these masterpieces bridge the
            gap between humanity and nature, serving as reminders of our role in
            the interconnected web of life and the need for conservation. <br />{" "}
            <br /> A vibrant ode to the wondrous diversity and splendour of the
            natural world. These creations invite us to explore, admire, and
            connect with the creatures that share our planet, urging us to be
            responsible stewards of Earth's magnificent biodiversity. Through
            this art, we find inspiration and motivation to preserve the rich
            tapestry of life that envelops us.
          </p>
        </div>
      </div>
    </>
  );
};

export default Realistic_Animal_Artistry;
