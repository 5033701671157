import React from "react";
import "../Uitheme/Ui.css";
import img from "../Images/image-000.jpg";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import Ourproduct from "./Ourproduct";
import { ScrollParallax } from "react-just-parallax";
import Proscess from "../Components/Proscess";

const Ui = () => {
  return (
    <>
      <ScrollParallax>
        <div className="ui">
          <div className="ui_img">
            <img className="kenburns-top-left" src={img} alt="img" />
          </div>
          <div className="ui_text">
            <h2>
              Planet Earth - Your Go-To Destination for Unique and modern art
              metal sculptures!
            </h2>
            <br /> <br />
            <p>
              Welcome to Planet Earth, a leading large metal sculpture
              manufacturer specialising in creating unique and inspiring fine
              metal art pieces. Our team of contemporary metal sculpture artists
              is dedicated to bringing your creative vision to life through
              abstract metal design and steel art sculptures.
            </p>
            <br />
            <p>
              Browse our extensive collection of abstract metal art sculptures
              and let us inspire you to create something truly unique and
              unforgettable!
            </p>
            <Link to="/about" className="btn">
              About Us
              <span>
                <BsArrowRight />
              </span>
            </Link>
          </div>
        </div>
      </ScrollParallax>
      <Proscess />
      <Ourproduct />
    </>
  );
};

export default Ui;

// https://imgbb.com/
