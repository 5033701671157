import React from "react";
import "../Pages/Contact.css";
import "aos/dist/aos.css";

const Contact = () => {
  return (
    <>
      <div data-aos="fade-up"
     data-aos-anchor-placement="top-bottom" className="contact">
        <div className="contact_heading">
          <h2>Get In Touch</h2>
        </div>
        <div className="from_main">
          <div className="contact_from">
            <form action="">
              <div className="fist_line">
                <input type="text" placeholder="Full Name*" required />
                <input type="text" placeholder="Email*" required />
              </div>
              <div className="sce_line">
                <input type="text" placeholder="Companey Name*" required />
                <input type="text" placeholder="Phone Number*" required />
              </div>
              <div className="text_area">
                <textarea
                  name="message"
                  id="message"
                  cols="30"
                  rows="10"
                  placeholder="Message*"
                ></textarea>
              </div>
              <button> Submit </button>
            </form>
          </div>
          <div className="contact_text">

            
            <h2>Let's talk.</h2>
        
            
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
