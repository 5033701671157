import "./App.css";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Navbar from "../src/Components/Navbar";
import Home from "../src/Pages/Home";
import About from "../src/Pages/About";
import Contact from "./Pages/Contact";
import UiBlogs from "./Components/UiBlogs";
import Footer from "./Components/Footer";
import Artist from "./Pages/Artist";
import News from "./Pages/News";
import Events from "./Pages/Events";
import { useEffect } from "react";
import Realistic_Animal_Artistry from "./Pages/Dropdown/Realistic_Animal_Artistry";
import MetalSculptures from "./Pages/Dropdown/MetalSculptures";
import Handmade_Metal_Art from "./Pages/Dropdown/Handmade_Metal_Art";
import Abstract_Art_Paintings from "./Pages/Dropdown/Abstract_Art_Paintings";

const ScrollingRouter = (props) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return <>{props.children}</>;
}


function App() {

  return (
    <>
      <BrowserRouter>
      <ScrollingRouter> 
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/uiblogs" element={<UiBlogs />} />
          <Route path="/artist" element={<Artist />} />
          <Route path="/news" element={<News />} />
          <Route path="/events" element={<Events/>} />
          <Route path="/realistic_Animal_Artistry" element={<Realistic_Animal_Artistry/>} />
          <Route path="/metalSculptures" element={<MetalSculptures/>} />
          <Route path="/handmade_Metal_Art" element={<Handmade_Metal_Art/>} />
          <Route path="/abstract_Art_Paintings" element={<Abstract_Art_Paintings/>} />
        </Routes>
        <Footer/>
        </ScrollingRouter>
      </BrowserRouter>
    </>
  );
}

export default App;
