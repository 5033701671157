import React from "react";
import "../Uitheme/Ourproduct.css";
import Data from "../Data.json";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
// import { Link } from "react-router-dom";
// import { BsArrowRight } from "react-icons/bs";

const Ourproduct = () => {

  const settings = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 500,
    cssEase: "linear",

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="container">
        <h3>Our Products</h3>
        <div className="row">
          <Slider {...settings}>
            {Data.map((value) => (
              <div className="col-md-4" key={value.no}>
                <div className="card">
                  <img src={value.img} alt="logo" className="card-img-top" />
                </div>
              </div>
            ))}
          </Slider>
        </div>

        {/* <div className="p_btn">
          <Link to="/products" className="btn">
            View All
            <span>
              <BsArrowRight />
            </span>
          </Link>
        </div> */}
      </div>
    </>
  );
};

export default Ourproduct;
