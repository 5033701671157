import React from 'react';
import { useState } from 'react';
import img from "../../Images/metal sculptures/1 (11).jpg";
import img2 from "../../Images/metal sculptures/1 (12).jpg";
import img3 from "../../Images/metal sculptures/1 (18).jpg";
import img4 from "../../Images/metal sculptures/1 (2).jpg";
import img5 from "../../Images/metal sculptures/1 (20).jpg";
import img6 from "../../Images/metal sculptures/1 (24).jpg";
import img7 from "../../Images/metal sculptures/1 (25).jpg";
import img8 from "../../Images/metal sculptures/1 (27).jpg";
import img9 from "../../Images/metal sculptures/1 (31).jpg";
import img10 from "../../Images/metal sculptures/1 (51).jpg";
import img11 from "../../Images/metal sculptures/1 (59).jpg";
import img12 from "../../Images/metal sculptures/1 (62).jpg";
import img13 from "../../Images/metal sculptures/1 (63).jpg";
import img14 from "../../Images/metal sculptures/1 (65).jpg";
import img15 from "../../Images/metal sculptures/1 (66).jpg";
import img16 from "../../Images/metal sculptures/a (19).jpg";
import img17 from "../../Images/metal sculptures/a (22).jpg";

const MetalSculptures = () => {


    const initialDisplayCount = 8;
    const [imgDisplayCount, setImgDisplayCount] = useState(initialDisplayCount);
    const [showMore, setShowMore] = useState(true);
  
  
    const handleButtonClick = () => {
      if (showMore) {
        setImgDisplayCount(imgData.length);
      } else {
        setImgDisplayCount(initialDisplayCount);
      }
      setShowMore(!showMore);
    };


    const imgData = [
        {
            id: 1,
            images: img
        },
        {
            id: 2,
            images: img2
        },
        {
            id: 3,
            images: img3
        },
        {
            id: 4,
            images: img4
        },
        {
            id: 5,
            images: img5
        },
        {
            id: 6,
            images: img6
        },
        {
            id: 7,
            images: img7
        },
        {
            id: 8,
            images: img8
        },
        {
            id: 9,
            images: img9
        },
        {
            id: 10,
            images: img10
        },
        {
            id: 11,
            images: img11
        },
        {
            id: 12,
            images: img12
        },
        {
            id: 13,
            images: img13
        },
        {
            id: 14,
            images: img14
        },
        {
            id: 15,
            images: img15
        },
        {
            id: 16,
            images: img16
        },
        {
            id: 17,
            images: img17
        },
    ]


  return (
    <>
    <div className="artist">
    <div className="gally">
      {imgData.slice(0, imgDisplayCount).map((item) => {
        return (
          <div key={item.id} className="imgbox">
            <img src={item.images} alt={item.name} />
          </div>
        );
      })}
    </div>
    <div className="btnshow">
    <button onClick={handleButtonClick}>
      {showMore ? "See More" : "Hide More"}
    </button>
    </div>
  </div>
</>
  )
}

export default MetalSculptures