import React from "react";
import "../Pages/About.css";
import img from "../Images/clint.png";

const About = () => {
  return (
    <>
      <div className="about_heading"></div>

      <div className="about">
        <div className="about_img">
          <div className="img">
            <img src={img} alt="" />
          </div>
        </div>
        <div className="about_text">
          <h2>Our Mission</h2>
          <p>
            Our mission is to revolutionize Singapore's décor scene by fusing
            artistry and sustainability. We aim to offer customers unmatched
            metal artwork that appeals to both modern and antique preferences.
            Through the integration of Indian and European manufacturing
            techniques, we deliver a craftsmanship blend unparalleled in the art
            industry.
          </p>
          <br /> <br />
          <h2>Our Values</h2>
          <p>
            At Planet Earth, our values are deeply rooted in our work. We
            believe in the power of detail, the beauty of craftsmanship, and the
            importance of excellent customer service. Our passion for
            sustainability is embedded in every product we create, reflecting
            our commitment to preserving the planet we all call home. We value
            our customers and strive fo provide them with quality products that
            are not only visually appealing but also meaningful and purposeful.
          </p>
          <br /> <br />
          <h2>Space Creator</h2>
          <p>
            At At Planet Earth, we value craftsmanship, quality, and innovation.
            We believe in creating products that enhance spaces and ignite the
            imagination, stir emotions, and celebrate the spirit of life. Our
            team is dedicated to blending traditional techniques with modern
            designs, creating contemporary and antique metal art pieces that
            capture the essence of our diverse world.
          </p>
          <br />
          <p>
            Our founder was motivated by a simple yet profound realization - art
            is not just about the aesthetic appeal but also about the emotions
            it evokes, the stories it tells, and the memories it creates. Having
            witnessed the magic of metal art during his travels in India and
            Europe, he decided to bring this enchanting art form to Singapore.
          </p>
        </div>
      </div>
    </>
  );
};

export default About;
