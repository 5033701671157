import React from "react";
import { useState } from "react";
import img from "../../Images/abstract art paintings/1.jpg";
import img2 from "../../Images/abstract art paintings/2.jpg";
import img3 from "../../Images/abstract art paintings/3.jpg";
import img4 from "../../Images/abstract art paintings/4.jpg";
import img5 from "../../Images/abstract art paintings/5.jpg";
import img6 from "../../Images/abstract art paintings/6.jpg";
import img7 from "../../Images/abstract art paintings/7.jpg";
import img8 from "../../Images/abstract art paintings/8.jpg";
import img9 from "../../Images/abstract art paintings/9.jpg";
import img10 from "../../Images/abstract art paintings/10.jpg";

const Abstract_Art_Paintings = () => {
  const initialDisplayCount = 8;
  const [imgDisplayCount, setImgDisplayCount] = useState(initialDisplayCount);
  const [showMore, setShowMore] = useState(true);

  const handleButtonClick = () => {
    if (showMore) {
      setImgDisplayCount(imgData.length);
    } else {
      setImgDisplayCount(initialDisplayCount);
    }
    setShowMore(!showMore);
  };

  const imgData = [
    {
      id: 1,
      images: img,
    },
    {
      id: 2,
      images: img2,
    },
    {
      id: 3,
      images: img3,
    },
    {
      id: 4,
      images: img4,
    },
    {
      id: 5,
      images: img5,
    },
    {
      id: 6,
      images: img6,
    },
    {
      id: 7,
      images: img7,
    },
    {
      id: 8,
      images: img8,
    },
    {
      id: 9,
      images: img9,
    },
    {
      id: 10,
      images: img10,
    },
  ];

  return (
    <>
      <div className="artist">
        <div className="gally">
          {imgData.slice(0, imgDisplayCount).map((item) => {
            return (
              <div key={item.id} className="imgbox">
                <img src={item.images} alt={item.name} />
              </div>
            );
          })}
        </div>
        <div className="btnshow">
          <button onClick={handleButtonClick}>
            {showMore ? "See More" : "Hide More"}
          </button>
        </div>

        <div className="headtext">
          <h2>Eternal Reverie: A Journey Through Painted Realms</h2>
          <p>
            In the realm of visual art, paintings are profound journeys into the
            artist's soul, where each stroke and splash of color reveals their
            innermost thoughts and creativity. They serve as windows into the
            human experience, freezing emotions and stories in time. Paintings
            transport us to diverse landscapes, captivate us with the play of
            light and shadow, and draw us into intricate expressions. Whether
            crafted narratives or interpretations, they provoke introspection,
            ignite the imagination, and evoke emotions, celebrating the artist's
            dedication and skill. <br /> <br /> In the world of paintings, a
            rich tapestry of styles, from classical to abstract, invites us to
            explore the boundless diversity of human creativity. These artworks
            are not just expressions; they are an invitation to see the world
            from different perspectives, to appreciate life's beauty and
            complexity anew. They celebrate human expression's infinite
            possibilities and remind us of art's enduring ability to touch our
            hearts and souls.
          </p>
        </div>
      </div>
    </>
  );
};

export default Abstract_Art_Paintings;
