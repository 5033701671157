import React, { useEffect, useRef } from "react";
import "../Pages/Home.css";
import img from "../Images/home.png";
import img2 from "../Images/1 (45).png";
import { BsArrowRight } from "react-icons/bs";
import Aos from "aos";
import "aos/dist/aos.css";
import Ui from "../Uitheme/Ui";
// import { Link } from "react-router-dom";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import Slider from "react-slick";
// import { GrFormPrevious, GrFormNext } from "react-icons/gr";
import video from "../Images/video.mp4"

const Home = () => {
  // useEffect(() => {
  //   Aos.init({ duration: 3000 });
  // }, []);

  // const sliderRef = useRef(null);

  // const next = () => {
  //   if (sliderRef.current) {
  //     sliderRef.current.slickNext();
  //   }
  // };

  // const previous = () => {
  //   if (sliderRef.current) {
  //     sliderRef.current.slickPrev();
  //   }
  // };

  // const Data = [
  //   {
  //     id: 1,
  //     text: "Elevate your space with Planet Earth The Leading Metal Sculpture Manufacturer for Stunning Artistic Designs!",
  //     images: img,
  //   },
  //   {
  //     id: 1,
  //     text: "Elevate your space with Planet Earth The Leading Metal Sculpture Manufacturer for Stunning Artistic Designs!",
  //     images: img2,
  //   },
  // ];

  // const settings = {
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   autoplay: true,
  //   autoplaySpeed: 3000,
  //   pauseOnHover: true,
  // };

  return (
    <>
      <div className="home">

      <video autoPlay loop muted>
          <source src={video} type="video/mp4" />
          {/* Add additional source elements for different video formats if needed */}
        </video>
        {/* <Slider ref={sliderRef} {...settings}>
          {Data.map((data) => {
            return (
              <>
                <div className="home_items">
                  <div className="home_text">
                    <h1 className="text-focus-in">{data.text}</h1>
                    <Link to="/contact" className="btn">
                      Start Now
                      <span>
                        <BsArrowRight />
                      </span>
                    </Link>
                  </div>
                  <div className="home_img">
                    <img data-aos="fade-up" src={data.images} alt="" />
                  </div>
                </div>
              </>
            );
          })}
        </Slider> */}

        {/* <div className="homebtn">
          <button className="prev-button" onClick={previous}>
            <GrFormPrevious/>
          </button>
          <button className="next-button" onClick={next}>
            <GrFormNext/>
          </button>
        </div> */}

        {/* <div className="wave"></div> */}
      </div>
      <Ui />
    </>
  );
};

export default Home;
