import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../Components/Navbar.css";
import { useRef } from "react";
// import { flushSync } from "react-dom";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const dropdownRef = useRef(null);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isDropdownOpen && dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isDropdownOpen]);

  const handleLinkClick = () => {
    setIsDropdownOpen(false);
    setIsMenuOpen(false);
  };
  



  return (
    <nav>
      <div className="navbar">
        <div className="logo">
          <Link to="/">Planet earth</Link>
        </div>
        <div className="nav-list">
          <Link to="/">Home</Link>
          <div className="dropdown" 
             ref={dropdownRef}
             onMouseEnter={() => setIsDropdownOpen(true)}>
          <Link to="/artist">Artist</Link>
          {isDropdownOpen && (
            <div className="dropdown-menu">
              <Link onClick={handleLinkClick} to="/realistic_Animal_Artistry">Realistic Animal Artistry</Link>
              <Link onClick={handleLinkClick} to="/handmade_Metal_Art">Handmade Metal Art</Link>
              <Link onClick={handleLinkClick} to="/metalSculptures">Metal Sculptures</Link>
              <Link onClick={handleLinkClick} to="/abstract_Art_Paintings">Abstract Art/ Paintings</Link>
            </div>
          )}
        </div>
          <Link to="/news">News</Link>
          <Link to="/events">Events</Link>
          <Link to="/about">About</Link>
          <Link to="/contact" className="button">Contact</Link>
        </div>
        <div className={`hamburger-menu ${isMenuOpen ? "isMenuOpen" : ""}`} onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
      </div>
      {isMenuOpen && (
        <div className="mobile-nav-list">
          <Link to="/" onClick={() => setIsMenuOpen(false)}>Home</Link>
          <div className="dropdown" 
             ref={dropdownRef}
             onMouseEnter={() => setIsDropdownOpen(true)}>
          <Link to="/artist">Artist</Link>
          {isDropdownOpen && (
            <div className="dropdown-menu">
              <Link onClick={handleLinkClick} to="/realistic_Animal_Artistry">Realistic Animal Artistry</Link>
              <Link onClick={handleLinkClick} to="/handmade_Metal_Art">Handmade Metal Art</Link>
              <Link onClick={handleLinkClick} to="/metalSculptures">Metal Sculptures</Link>
              <Link onClick={handleLinkClick} to="/abstract_Art_Paintings">Abstract Art/ Paintings</Link>
            </div>
          )}
        </div>
          <Link to="/news" onClick={() => setIsMenuOpen(false)}>News</Link>
          <Link to="/events" onClick={() => setIsMenuOpen(false)}>Events</Link>
          <Link to="/about" onClick={() => setIsMenuOpen(false)}>About</Link>
          <Link to="/contact" className="button" onClick={() => setIsMenuOpen(false)}>Contact</Link>
        </div>
      )}
    </nav>
  );
};

export default Navbar;