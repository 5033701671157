import React from "react";
import "../Pages/Artist.css";
import { useState } from "react";
import img from "../Images/1 (45).png";
import img2 from "../Images/1.jpeg";
import img3 from "../Images/10.jpeg";
import img4 from "../Images/11.jpeg";
import img5 from "../Images/12.jpeg";
import img6 from "../Images/13.jpeg";
import img7 from "../Images/14.jpeg";
import img8 from "../Images/15.jpeg";
import img9 from "../Images/16.jpeg";
import img10 from "../Images/17.jpeg";
import img11 from "../Images/18.jpeg";
import img12 from "../Images/19.jpeg";

const Artist = () => {
  const initialDisplayCount = 8;
  const [imgDisplayCount, setImgDisplayCount] = useState(initialDisplayCount);
  const [showMore, setShowMore] = useState(true);


  const handleButtonClick = () => {
    if (showMore) {
      setImgDisplayCount(imgData.length);
    } else {
      setImgDisplayCount(initialDisplayCount);
    }
    setShowMore(!showMore);
  };

  const imgData = [
    {
      id: 1,
      images: img,
    },
    {
      id: 2,
      images: img2,
    },
    {
      id: 3,
      images: img3,
    },
    {
      id: 4,
      images: img4,
    },
    {
      id: 5,
      images: img5,
    },
    {
      id: 6,
      images: img6,
    },
    {
      id: 7,
      images: img7,
    },
    {
      id: 8,
      images: img8,
    },
    {
      id: 9,
      images: img9,
    },
    {
      id: 10,
      images: img10,
    },
    {
      id: 11,
      images: img11,
    },
    {
      id: 12,
      images: img12,
    },
  ];

  return (
    <>
      <div className="artist">
        <div className="gally">
          {imgData.slice(0, imgDisplayCount).map((item) => {
            return (
              <div key={item.id} className="imgbox">
                <img src={item.images} alt={item.name} />
              </div>
            );
          })}
        </div>
        <div className="btnshow">
        <button onClick={handleButtonClick}>
          {showMore ? "See More" : "Hide More"}
        </button>
        </div>
      </div>
    </>
  );
};

export default Artist;
