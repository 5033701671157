import React from "react";
import "../Pages/Events.css";
import img from "../Images/events/1.png";
import img2 from "../Images/events/2.png";
import img3 from "../Images/events/3.png";
import img4 from "../Images/events/4.png";
import img5 from "../Images/events/5.png";
import img6 from "../Images/events/6.png";
import img7 from "../Images/events/7.png";
import img8 from "../Images/events/8.png";
import img9 from "../Images/events/9.png";
import { useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";

const Events = () => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const boxData = [
    {
      id: 1,
      img: img,
    },
    {
      id: 2,
      img: img2,
    },
    {
      id: 3,
      img: img3,
    },
    {
      id: 4,
      img: img4,
    },
    {
      id: 5,
      img: img5,
    },
    {
      id: 6,
      img: img6,
    },
    {
      id: 7,
      img: img7,
    },
    {
      id: 8,
      img: img8,
    },
    {
      id: 9,
      img: img9,
    },
  ];

  const openModal = (img) => {
    setSelectedImage(img);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="events">
        <div className="evegla">
          {boxData.map((item) => {
            return (
              <div className="boximg" key={item.id}>
                <div className="imgboxeve" onClick={() => openModal(item.img)}>
                  <img src={item.img} alt="img" />
                </div>
                {/* <div className="text">
                  <span>{item.name}</span>
                </div> */}
              </div>
            );
          })}
        </div>
      </div>

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <img src={selectedImage} alt="Full-size" />
            <button onClick={closeModal}><AiFillCloseCircle/></button>
          </div>
        </div>
      )}
    </>
  );
};

export default Events;