import React from "react";
import "../Components/Proscess.css";
// import { ScrollParallax } from "react-just-parallax";

const Proscess = () => {
  return (
    <div className="proscess">
      <div className="proscess_heading">
        <h2>
          Shaping the Future: The <br /> Art of Metal Manufacturing!
        </h2>
      </div>

      <div className="proscess_box">
        {/* box_1  */}

        <div className="box_proscess_1 box_proscess">
          <h3>Design and Concept Development</h3>
          <p>
            At Planet Earth, we are passionate about crafting exquisite metal
            sculpture art that adds depth to any space. Our team of contemporary
            metal sculpture artists excels at creating unique, boundary-pushing
            works that bring your vision to life.
          </p>
          <p>
            We possess the expertise to produce outdoor abstract metal
            sculptures and large metal art pieces that leave a lasting
            impression, ranging from abstract steel art to modern metal
            sculptures. Our metal sculpture artworks showcase the finest
            examples of our craftsmanship and commitment to excellence.
          </p>
          <p>
            Place your trust in us to create your metal art statues, and let our
            steel sculpture artists elevate your space to new heights.
          </p>
        </div>

        {/* box_2  */}

        <div className="box_proscess_1 box_proscess">
          <h3>Precision engineering</h3>
          <p>
            When it comes to moulded steel, we at Planet Earth take great pride
            in our precision engineering. Our team of experts creates a metal
            art abstract that is both aesthetically pleasing and structurally
            sound. We are dedicated to producing metal sculpture that exceeds
            the standards set by our clients.
          </p>
          <p>
            We ensure that every piece of metal art—whether it's fine metal art
            for commercial projects or metal sculpture art for sale—is crafted
            with the utmost care and attention to detail. We are pleased to be
            acknowledged as perfectionist outdoor metal sculpture artists whose
            steel art sculptures are made to last.
          </p>
        </div>

        {/* box_3  */}

        <div className="box_proscess_1 box_proscess">
          <h3>Metal working</h3>
          <p>
            Your premier destination for top-quality metal sculptures. We
            seamlessly blend cutting-edge technology with traditional
            craftsmanship to create our metal artworks. Our specialization lies
            in crafting contemporary steel sculptures, perfect for adding an
            artistic touch to your space, including large steel and abstract
            metal wall sculptures.
          </p>
          <p>
            We collaborate with the world's leading metal sculptors to offer you
            stunning metal artworks guaranteed to captivate. Explore our
            selection of available metal sculptures today and find the perfect
            addition to your collection.
          </p>
        </div>

        {/* box_4  */}

        <div className="box_proscess_1 box_proscess">
          <h3>Finishing</h3>
          <p>
            We believe that metal polishing is integral to the fabrication
            process. We take pride in our expertise in metal finishing,
            resulting in stunning metal wall sculptures. Our polishing procedure
            adds a distinctive and refined touch to each contemporary metal
            sculpture.
          </p>
          <p>
            Explore our collection of large metal sculptures for sale, including
            indoor and outdoor abstract metal wall art. Find the perfect piece
            for your space today.
          </p>
        </div>

        {/* box_5  */}

        <div className="box_proscess_1 box_proscess">
          <h3>Assembly</h3> <br /> <br />
          <p>
            Each metal sculpture is meticulously assembled by our team of
            experts who take great care in the process. We take pride in
            delivering enduring, high-quality products that exceed our clients'
            expectations.
          </p>
          <br />
        </div>

        {/* box_6  */}

        <div className="box_proscess_1 box_proscess">
          <h3>Delivery and installing</h3> <br />
          <p>
            All our metal sculptures are delivered and installed by our reliable
            and skilled team of professionals. We ensure secure delivery and
            placement of your sculpture, anywhere in the world, to your complete
            satisfaction.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Proscess;
